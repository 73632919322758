<template>
  <svg class="skills_header_svg" width="130" height="130" viewBox="0 0 130 130">
    <g id="Group_1288" data-name="Group 1288" transform="translate(-764 -35)">
      <path
        id="Path_1810"
        data-name="Path 1810"
        d="M77.667,12a1.667,1.667,0,1,0,1.667,1.667A1.667,1.667,0,0,0,77.667,12Z"
        transform="translate(796.333 24.667)"
        fill="#6c63ff"
      />
      <path
        id="Path_1811"
        data-name="Path 1811"
        d="M74.667,13a61.667,61.667,0,1,0,61.667,61.667A61.667,61.667,0,0,0,74.667,13Z"
        transform="translate(754 25)"
      />
      <path
        id="Path_1812"
        data-name="Path 1812"
        d="M85.667,11a6.667,6.667,0,1,0,6.667,6.667A6.667,6.667,0,0,0,85.667,11Z"
        transform="translate(798.333 24)"
        fill="#6c63ff"
      />
      <path
        id="Path_1813"
        data-name="Path 1813"
        d="M88.333,22a3.333,3.333,0,1,0,3.333,3.333A3.333,3.333,0,0,0,88.333,22Z"
        transform="translate(802.333 31.333)"
      />
      <path
        id="Path_1814"
        data-name="Path 1814"
        d="M127.667,84A3.333,3.333,0,1,0,131,87.333,3.333,3.333,0,0,0,127.667,84Zm-110-25a6.667,6.667,0,1,0,6.667,6.667A6.667,6.667,0,0,0,17.667,59Z"
        transform="translate(753 56)"
        fill="#6c63ff"
      />
      <path
        id="Path_1815"
        data-name="Path 1815"
        d="M26.333,85a3.333,3.333,0,1,0,3.333,3.333A3.333,3.333,0,0,0,26.333,85Z"
        transform="translate(761 73.333)"
      />
      <path
        id="Path_1816"
        data-name="Path 1816"
        d="M20.167,60.333A4.167,4.167,0,1,0,24.333,64.5,4.167,4.167,0,0,0,20.167,60.333ZM121.833,32a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,121.833,32Z"
        transform="translate(756.333 38)"
        fill="#fff"
      />
      <g id="noun_skill_2040784" transform="translate(789 62)">
        <path
          id="Path_2388"
          data-name="Path 2388"
          d="M50.75,50h-30a3.6,3.6,0,1,0,0,7.2h30a3.6,3.6,0,1,0,0-7.2Z"
          transform="translate(3.75 9.6)"
        />
        <path
          id="Path_2389"
          data-name="Path 2389"
          d="M21,57v2.4A3.677,3.677,0,0,0,24.75,63h20a3.677,3.677,0,0,0,3.75-3.6V57Z"
          transform="translate(4.75 11)"
          fill="#ef7974"
        />
        <path
          id="Path_2390"
          data-name="Path 2390"
          d="M34.25,2.028v8.366a1.225,1.225,0,0,1-1.25,1.2H30.5a2.4,2.4,0,1,0,0,4.8H33a1.225,1.225,0,0,1,1.25,1.2v14.4h7.5a5,5,0,0,1,10,0h8.568a26.971,26.971,0,0,0,.182-3.059V27.282C60.5,13.7,48.857,2.631,34.25,2.028ZM49.343,18.286l-5.308,5.308-5.308-5.308a3.217,3.217,0,1,1,4.549-4.55l.758.758.758-.758a3.217,3.217,0,1,1,4.549,4.55Z"
          transform="translate(6.5 0.006)"
        />
        <path
          id="Path_2391"
          data-name="Path 2391"
          d="M39.888,36.2h-2.5a1.225,1.225,0,0,1-1.25-1.2V29h-6.25v.6a5.63,5.63,0,0,1-11.25,0V29H10.448A39.423,39.423,0,0,0,20.582,48.58L21.138,53h15V42.2a1.225,1.225,0,0,1,1.25-1.2h2.5a2.4,2.4,0,1,0,0-4.8ZM29.263,44.6h-.625v3.6l-3.75-3.6h-.625a3,3,0,1,1,0-6h5a3,3,0,1,1,0,6Z"
          transform="translate(2.112 5.4)"
        />
        <path
          id="Path_2392"
          data-name="Path 2392"
          d="M35,9.194h1.25V2.03C21.717,2.551,10.168,13.928,10,27.888v1.047a26.97,26.97,0,0,0,.182,3.059H20a1.225,1.225,0,0,1,1.25,1.2v1.8a3.128,3.128,0,0,0,6.25,0v-1.8a1.225,1.225,0,0,1,1.25-1.2h7.5v-13.2H35a4.9,4.9,0,0,1-5-4.8A4.9,4.9,0,0,1,35,9.194ZM24.887,21.41l1.475,4.584L22.5,23.161l-3.863,2.833,1.475-4.584L16.25,18.578h4.775L22.5,13.994l1.475,4.584H28.75Z"
          transform="translate(2 0.006)"
        />
        <path
          id="Path_2393"
          data-name="Path 2393"
          d="M49.25,29.8A1.225,1.225,0,0,1,48,28.6V27.4a2.5,2.5,0,0,0-5,0v1.2a1.225,1.225,0,0,1-1.25,1.2H33v4.8h1.25a4.8,4.8,0,1,1,0,9.6H33v9.6H48l.556-4.419A39.423,39.423,0,0,0,58.689,29.8ZM48,39.4V43H45.5V39.4H41.75V37H45.5V33.4H48V37h3.75v2.4Z"
          transform="translate(7.75 4.6)"
        />
        <g id="skills_header_dot" data-name="Skills Header Dot">
          <rect
            id="Rectangle_385"
            data-name="Rectangle 385"
            width="2.5"
            height="2.4"
            transform="translate(4.5 2)"
          />
          <rect
            id="Rectangle_386"
            data-name="Rectangle 386"
            width="2.5"
            height="2.4"
            transform="translate(4.5 6.8)"
          />
          <rect
            id="Rectangle_387"
            data-name="Rectangle 387"
            width="2.5"
            height="2.4"
            transform="translate(7 4.4)"
          />
          <rect
            id="Rectangle_388"
            data-name="Rectangle 388"
            width="2.5"
            height="2.4"
            transform="translate(2 4.4)"
          />
          <rect
            id="Rectangle_389"
            data-name="Rectangle 389"
            width="2.5"
            height="2.4"
            transform="translate(72 2)"
          />
          <rect
            id="Rectangle_390"
            data-name="Rectangle 390"
            width="2.5"
            height="2.4"
            transform="translate(72 6.8)"
          />
          <rect
            id="Rectangle_391"
            data-name="Rectangle 391"
            width="2.5"
            height="2.4"
            transform="translate(74.5 4.4)"
          />
          <rect
            id="Rectangle_392"
            data-name="Rectangle 392"
            width="2.5"
            height="2.4"
            transform="translate(69.5 4.4)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
    name: "SkillHeaderIcon"
}
</script>

<style lang="scss" scoped>
:root {
  #Path_2392,
  #Path_2390,
  #Path_2391,
  #Path_2393,
  #Path_2388,
  #Path_2389,
  #skills_header_dot rect,
  #Path_1815,
  #Path_1813 {
    fill: #ef7974;
  }
  #Path_1811 {
    fill: #6c63ff;
    opacity: 0.147;
  }
}
:root.dark-theme {
  #Path_2392,
  #Path_2390,
  #Path_2391,
  #Path_2393,
  #Path_2388,
  #Path_2389,
  #skills_header_dot rect,
  #Path_1815,
  #Path_1813 {
    fill: #f8bf7b;
  }
  #Path_1811 {
    fill: #1e2129;
    opacity: 1;
  }
}
</style>