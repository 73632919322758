<template>
  <svg
    class="skills_leaves_svg_container"
    width="80.463"
    height="173.226"
    viewBox="0 0 90.463 183.226"
  >
    <g id="Group_988" data-name="Group 988" transform="translate(-149.401 -520.902)">
      <path
        id="Path_1298"
        data-name="Path 1298"
        d="M157.22,676.6s2.74-9.777,14.416-6.842c0,0-3.9,9.291-13.649,8.508Z"
        transform="translate(33.601 -24.326)"
      />
      <path
        id="Path_1299"
        data-name="Path 1299"
        d="M148.27,649.716s2.107-11.956,13-9.31c0,0-3.164,10.877-12.192,11.051Z"
        transform="translate(38.938 -6.683)"
      />
      <path
        id="Path_1300"
        data-name="Path 1300"
        d="M139.88,622.26s2.53-12.051,13.536-8.547c0,0-4.322,11.391-12.769,10.212Z"
        transform="translate(43.941 9.608)"
      />
      <path
        id="Path_1301"
        data-name="Path 1301"
        d="M134.69,592.067s4.012-12.055,12.531-11.727c0,0-4.1,12.732-11.821,14.144Z"
        transform="translate(47.037 29.399)"
      />
      <path
        id="Path_1302"
        data-name="Path 1302"
        d="M131.39,563.833s4.367-14.511,11.3-13.388c0,0-3.023,11.074-11.054,15.651Z"
        transform="translate(49.006 47.504)"
      />
      <path
        id="Path_1303"
        data-name="Path 1303"
        d="M129.51,534.565s4.1-12.091,10.941-12.565c0,0-2.789,11.9-10.788,15.714Z"
        transform="translate(50.127 64.661)"
      />
      <path
        id="Path_1304"
        data-name="Path 1304"
        d="M127.46,506.34s5.109-12.107,9.629-12.38c0,0-2.216,11.921-9.177,14.365Z"
        transform="translate(51.349 81.61)"
      />
      <path
        id="Path_1305"
        data-name="Path 1305"
        d="M126.05,476.949s3.14-13.147,7.668-14.239c0,0-.617,10.75-7.2,15.219Z"
        transform="translate(52.191 100.501)"
      />
      <path
        id="Path_1306"
        data-name="Path 1306"
        d="M121.85,445.545s3.3-10.477,6.958-12.075c0,0-.722,12.352-6.11,14.634Z"
        transform="translate(54.695 118.176)"
      />
      <path
        id="Path_1307"
        data-name="Path 1307"
        d="M117.356,412.9l-.666-18.474s6.829,13.946,1.17,21.734Z"
        transform="translate(57.773 141.775)"
      />
      <path
        id="Path_1308"
        data-name="Path 1308"
        d="M96.236,372.958s-4.924-13.491-6.106-14.239c0,0,9.658,8.753,8.713,20.013Z"
        transform="translate(73.613 163.361)"
      />
      <path
        id="Path_1311"
        data-name="Path 1311"
        d="M148.84,713.3s-9.085-4.157-14.9,6.6c0,0,8.394,3.477,15.7-4.861Z"
        transform="translate(47.484 -50.502)"
      />
      <path
        id="Path_1312"
        data-name="Path 1312"
        d="M134.9,686.706s-9.3-4.446-14.767,6.087c0,0,9.686,3.267,15.534-4.422Z"
        transform="translate(55.721 -34.339)"
      />
      <path
        id="Path_1313"
        data-name="Path 1313"
        d="M124.769,659.537s-8.951-8.385-16.1.095c0,0,9.149,6.9,16.511,1.772Z"
        transform="translate(62.557 -16.243)"
      />
      <path
        id="Path_1314"
        data-name="Path 1314"
        d="M117.349,629.931s-9.008-8.575-16.1.4c0,0,10.114,7.032,16.418,1.4Z"
        transform="translate(66.981 1.653)"
      />
      <path
        id="Path_1315"
        data-name="Path 1315"
        d="M114.67,595.94s-6.938-10.679-15.11-8.306c0,0,7.2,11.335,15.033,10.829Z"
        transform="translate(67.989 25.194)"
      />
      <path
        id="Path_1316"
        data-name="Path 1316"
        d="M111.789,566.774s-7-13.483-13.609-11.142c0,0,5.041,10.362,13.786,13.408Z"
        transform="translate(68.813 44.495)"
      />
      <path
        id="Path_1317"
        data-name="Path 1317"
        d="M110.124,537.59s-7.188-10.616-13.924-9.318c0,0,5.876,10.762,14.622,12.388Z"
        transform="translate(69.993 60.936)"
      />
      <path
        id="Path_1318"
        data-name="Path 1318"
        d="M114.43,506.3s-5.569-11.909-10.09-12.012c0,0,2.676,11.826,9.726,14.013Z"
        transform="translate(65.139 81.411)"
      />
      <path
        id="Path_1319"
        data-name="Path 1319"
        d="M110.578,479.583S104.383,467.524,99.73,467.5c0,0,3.172,10.311,10.638,13.147Z"
        transform="translate(67.888 97.605)"
      />
      <path
        id="Path_1320"
        data-name="Path 1320"
        d="M111.775,448.854s-4.633-9.983-8.475-11.114c0,0,2.317,12.166,7.959,13.76Z"
        transform="translate(65.759 115.595)"
      />
      <path
        id="Path_1321"
        data-name="Path 1321"
        d="M101.69,416.643,91.33,401.19s2.652,15.243,11.861,18.4Z"
        transform="translate(72.897 137.689)"
      />
      <path
        id="Path_1322"
        data-name="Path 1322"
        d="M80.947,383.441S68.916,375.3,68.48,374c0,0,6.138,11.4,17.483,13.412Z"
        transform="translate(86.526 154.125)"
      />
      <path
        id="Path_1323"
        data-name="Path 1323"
        d="M71.944,368.741s-13.815-13.012-17.354-13a22.608,22.608,0,0,1,17.673,12.308Z"
        transform="translate(94.811 165.162)"
      />
      <g id="skills_leaves_stem" data-name="Skills Leaves Stem">
        <path
          id="Path_1324"
          data-name="Path 1324"
          d="M107.5,419.454l-.5.063c-2.6-19.914-6.381-31.681-10.659-33.136l.17-.471C101.046,387.453,104.831,399.053,107.5,419.454Z"
          transform="translate(69.91 146.925)"
        />
        <path
          id="Path_1325"
          data-name="Path 1325"
          d="M126.842,516.077l-.759.063c-.161-1.859-.283-3.761-.367-5.656-.722-16.485-1.848-31.617-3.326-42.965l.44-.53c1.481,11.363,2.922,26.958,3.632,43.459C126.563,512.336,126.672,514.226,126.842,516.077Z"
          transform="translate(54.373 97.913)"
        />
        <path
          id="Path_1326"
          data-name="Path 1326"
          d="M190.346,679.062c-.133-.059-13.516-5.913-27.411-19.886a108.223,108.223,0,0,1-20.038-27.82c-6.421-12.807-10.336-28.552-11.647-43.855l.807.364c2.95,34.347,18.9,57.828,31.592,70.6,13.758,13.843,26.992,19.645,27.125,19.7Z"
          transform="translate(49.089 25.067)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
    name: "SkillLeaf"
}
</script>

<style lang="scss" scoped>
:root {
  .skills_leaves_svg_container path {
    fill: #b6c867;
  }
  #skills_leaves_stem path {
    fill: #9a9a9a;
  }
}
:root.dark-theme {
  .skills_leaves_svg_container path {
    fill: #5c7d56;
  }
  #skills_leaves_stem path {
    fill: #f8f8fd;
  }
}
</style>