<template>
  <div class="skills-component">
    <div class="component-nav-icon">
      <!-- <img src="@/assets/icons/skills_header_icon.svg" alt srcset /> -->
      <SkillHeaderIcon />

      <div class="c-n-title">
        <span>SKILLS</span>
        <span>&nbsp;</span>
      </div>
    </div>

    <div class="s-c-content">
      <div class="s-c-c-title">
        <span>CODING&nbsp;</span>
        <span>&</span>
        <span>&nbsp;DESIGN SKILLS</span>
      </div>

      <v-container fluid ma-0>
        <v-row dense>
          <v-col class="pa-4" cols="12" sm="6" md="6" lg="4">
            <div class="s-c-c-tile-container">
              <div class="s-c-c-leaf">
                <!-- <img src="@/assets/icons/skills_leaf.svg" alt srcset /> -->
                <SkillLeaf />
              </div>
              <v-card class="s-c-c-tile-card js-frmw elevation-0" width="400">
                <v-card-title>
                  <div class="bg"></div>
                  <span class="txt">JavaScript Framework/Libraries</span>
                </v-card-title>
                <v-card-text>
                  <ul>
                    <li>Angular</li>
                    <li>Vue.js</li>
                    <li>React.js</li>
                    <li>Next.js</li>
                    <li>jQuery</li>
                  </ul>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col class="pa-4" cols="12" sm="6" md="6" lg="4">
            <div class="s-c-c-tile-container">
              <div class="s-c-c-leaf">
                <!-- <img src="@/assets/icons/skills_leaf.svg" alt srcset /> -->
                <SkillLeaf />
              </div>
              <v-card class="s-c-c-tile-card cmp-frmw elevation-0" width="400">
                <v-card-title>
                  <div class="bg"></div>
                  <span class="txt">Component Framework</span>
                </v-card-title>
                <v-card-text>
                  <ul>
                    <li>Angular Material</li>
                    <li>Bootstrap 4</li>
                    <li>Zurb Foundation</li>
                    <li>Django</li>
                  </ul>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col class="pa-4" cols="12" sm="6" md="6" lg="4">
            <div class="s-c-c-tile-container">
              <div class="s-c-c-leaf">
                <!-- <img src="@/assets/icons/skills_leaf.svg" alt srcset /> -->
                <SkillLeaf />
              </div>
              <v-card class="s-c-c-tile-card viz-tools elevation-0" width="400">
                <v-card-title>
                  <div class="bg"></div>
                  <span class="txt">Visualization Tools</span>
                </v-card-title>
                <v-card-text>
                  <ul>
                    <li>Highcharts</li>
                    <li>D3.js</li>
                    <li>NVD3</li>
                    <li>Morris.js</li>
                    <li>jQuery Sparklines</li>
                  </ul>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col class="pa-4" cols="12" sm="6" md="6" lg="4">
            <div class="s-c-c-tile-container">
              <div class="s-c-c-leaf">
                <!-- <img src="@/assets/icons/skills_leaf.svg" alt srcset /> -->
                <SkillLeaf />
              </div>
              <v-card class="s-c-c-tile-card dsgn-tools elevation-0" width="400">
                <v-card-title>
                  <div class="bg"></div>
                  <span class="txt">Design Tools</span>
                </v-card-title>
                <v-card-text>
                  <ul>
                    <li>Adobe XD CC</li>
                    <li>Figma</li>
                    <li>Photoshop</li>
                  </ul>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col class="pa-4" cols="12" sm="6" md="6" lg="4">
            <div class="s-c-c-tile-container">
              <div class="s-c-c-leaf">
                <!-- <img src="@/assets/icons/skills_leaf.svg" alt srcset /> -->
                <SkillLeaf />
              </div>
              <v-card class="s-c-c-tile-card vrsn-tools elevation-0" width="400">
                <v-card-title>
                  <div class="bg"></div>
                  <span class="txt">Version Control Tools</span>
                </v-card-title>
                <v-card-text>
                  <ul>
                    <li>GitHub</li>
                    <li>GitLab</li>
                    <li>SVN</li>
                    <li>GitBucket</li>
                  </ul>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col class="pa-4" cols="12" sm="6" md="6" lg="4">
            <div class="s-c-c-tile-container">
              <div class="s-c-c-leaf">
                <!-- <img src="@/assets/icons/skills_leaf.svg" alt srcset /> -->
                <SkillLeaf />
              </div>
              <v-card class="s-c-c-tile-card othr-tools elevation-0" width="400" color="#BDD2B6">
                <v-card-title>
                  <div class="bg"></div>
                  <span class="txt">Other Tools</span>
                </v-card-title>
                <v-card-text>
                  <ul>
                    <li>TypeScript</li>
                    <li>SCSS</li>
                    <li>Gulp</li>
                    <li>Webpack</li>
                  </ul>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import SkillLeaf from "../SVG/SkillLeaf";
import SkillHeaderIcon from "../SVG/SkillHeaderIcon";
export default {
  name: "Skills",
  components: {
    SkillLeaf, SkillHeaderIcon
  }
}
</script>
